import gql from 'graphql-tag'

const detailResponse = `
  id name isActive
  contactId isPassword isGoogle
  groups {id code name}
`

export const LIST_USER = (templateType) => gql`query LIST_USER ($userType: String!, $q: FilterInput) {
  users: list${templateType} (userType: $userType, q: $q) {
    id name isActive isGoogle isPassword
  }
}`

export const DETAIL_USER = (templateType) => gql`query DETAIL_USER ($userType: String!, $userId: Int!) {
  user: detail${templateType} (userType: $userType, userId: $userId) {${detailResponse}}
}`

export const CREATE_USER = (templateType) => gql`mutation CREATE_USER ($userType: String!, $input: ${templateType}Input!) {
  createUser: create${templateType} (userType: $userType, input: $input) {${detailResponse}}
}`

export const UPDATE_USER = (templateType) => gql`mutation UPDATE_USER ($userType: String!, $userId: Int!, $input: ${templateType}Input!) {
  updateUser: update${templateType} (userType: $userType, userId: $userId, input: $input) {${detailResponse}}
}`

export const DESTROY_USER = (templateType) => gql`mutation DESTROY_USER ($userType: String!, $userId: Int!) {
  destroyUser: destroy${templateType} (userType: $userType, userId: $userId) {id}
}`

export const CREATE_USER_GROUP = (templateType) => gql`mutation CREATE_USER_GROUP ($userType: String!, $groupId: Int!, $userId: Int!) {
  createUserGroup: create${templateType}Group (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const DESTROY_USER_GROUP = (templateType) => gql`mutation DESTROY_USER_GROUP ($userType: String!, $groupId: Int!, $userId: Int!) {
  destroyUserGroup: destroy${templateType}Group (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const RESET_USERNAME = (templateType) => gql`mutation RESET_USERNAME ($userType: String!, $userId: Int!, $username: String!, $password: String!) {
  resetUserUsername: reset${templateType}Username (userType: $userType, userId: $userId, username: $username, password: $password)
}`

export const GENERATE_GOOGLE_REGISTER = (templateType) => gql`mutation GENERATE_GOOGLE_REGISTER ($userType: String!, $userId: Int!) {
  token: generate${templateType}GoogleRegister (userType: $userType, userId: $userId)
}`

export const REVOKE_GOOGLE_REGISTER = (templateType) => gql`mutation REVOKE_GOOGLE_REGISTER ($userType: String!, $userId: Int!) {
  result: revoke${templateType}GoogleRegister (userType: $userType, userId: $userId)
}`

export const LIST_GROUP = (templateType) => gql`query LIST_GROUP ($userType: String!, $q: FilterInput) {
  groups: list${templateType}DropdownGroup (userType: $userType, q: $q) {
    id code name
  }
}`

export const DROPDOWN_CONTACT = (templateType) => gql`query DROPDOWN_CONTACT ($userType: String!, $q: FilterInput) {
  items: list${templateType}DropdownContact (userType: $userType, q: $q) {
    id type code name
  }
}`
